<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Aplicaciones</h1>
		</div>
		<br />

		<!-- <DataGrid :config="dataGridConfig" :data="usuarios" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones"> -->
		<DataGrid :config="dataGridConfig" :data="aplicaciones" :select="seleccionar" @actions="dataGridActions">
			<div class="col-sm-2">
				<button class="btn warning-btn" @click="obtener_aplicaciones">Recargar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','crear_aplicacion')" class="btn principal-btn" @click="modalApp=1">Nuevo</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','editar_aplicacion')" class="btn secondary-btn" @click="pre_editar">Editar</button>
			</div>
			<div class="col-sm-2">
				<button v-if="$auth.can('account','eliminar_aplicacion')" class="btn danger-btn" @click="pre_eliminar">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="modalApp!=0" @close="cancelar_app" class="bpb-modal">
			<form @submit="guardar">
				<h2 class="title">Aplicación</h2>
				<div class="body">
					<div class="row form-group">
						<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
						<div class="col-sm-10"><input v-model="aplicacion.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
						<div class="col-sm-10"><input v-model="aplicacion.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
					</div>
					<div class="row form-group">
						<label for="" class="col-form-label col-sm-2">Permisos</label>
						<div class="col-sm-10">
							<div class="container-fluid list_permisos">
								<div class="row permisos-header">
									<div class="col-sm-3">Permiso</div>
									<div class="col-sm-7">Descripción</div>
									<div class="col-sm-2">Estatus</div>
								</div>
								<div v-for="permiso in permisos" :key="permiso.id" class="row permisos-list">
									<div class="col-sm-3 permiso_cell">{{ permiso.permiso }}</div>
									<div class="col-sm-7">{{ permiso.descripcion }}</div>
									<div class="col-sm-1">{{ convertir_estatus(permiso.estatus) }}</div>
									<div class="col-sm-1">
										<img src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Quitar" @click="quitar_permiso(permiso.id)" title="Quitar permiso">
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="footer">
					<div class="row">
						<div class="col-sm-12 d-flex justify-content-end">
							<div class="col-sm-3">
								<button type="button" class="btn principal-btn" @click="ver_permisos=true">Agregar permiso</button>
							</div>
							<div class="col-sm-3">
								<button class="btn secondary-btn">Guardar</button>
							</div>
							<div class="col-sm-3">
								<button type="button" class="btn complementary-btn" @click="cancelar_app">Cancelar</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</Modal>

		<Modal v-if="eliminar" @close="cancelar_eliminar" :options="{width: '30vw', type:'error'}" class="bpb-modal">
			<h2 class="title">Eliminar aplicacion</h2>
			<div class="body">
				<p class="text-center">¿Realmente eliminarás la organizacion seleccionada?</p>
			</div>
			<div class="footer">
				<div class="col-sm-12 d-flex justify-content-end">
					<div class="col-sm-3">
						<button class="btn principal-btn" @click="eliminar_aplicaciones">Eliminar</button>
					</div>
					<div class="col-sm-3">
						<button class="btn complementary-btn" @click="cancelar_eliminar">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="ver_permisos" :options="{width: '40vw'}" @close="cancelar_permiso" class="bpb-modal">
			<h2 class="title">Agregar permiso</h2>
			<div class="body">
				<div class="row form-group">
					<label for="permiso" class="col-form-label col-sm-2">Permiso</label>
					<div class="col-sm-10"><input v-model="permiso.permiso" type="text" name="permiso" id="permiso" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="descripcion" class="col-form-label col-sm-2">Descripción</label>
					<div class="col-sm-10"><input v-model="permiso.descripcion" type="text" name="descripcion" id="descripcion" class="form-control"></div>
				</div>
				<div class="row form-group">
					<label for="estatus" class="col-form-label col-sm-2">Estatus</label>
					<div class="col-sm-10">
						<select v-model="permiso.estatus" name="estatus" id="estatus" class="form-control">
							<option value="1">Activo</option>
							<option value="0">Inactivo</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-3">
							<button class="btn principal-btn" @click="agregar_permiso">Agregar</button>
						</div>
						<div class="col-sm-3">
							<button class="btn complementary-btn" @click="cancelar_permiso">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'

	import api from '@/apps/account/api/account'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'aplicaciones'
					,cols: {
						id: 'ID'
						,nombre: 'Nombre'
						,descripcion: 'Descripción'
					}
					,filters: {
						cols: {
							id: 'ID'
							,nombre: 'Nombre'
							,descripcion: 'Descripción'
							,created_at: 'Fecha de creación'
						}
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
				}
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,aplicaciones: []
				,seleccionar: false
				,seleccion: []
				,aplicacion: {
					nombre: null
					,descripcion: null
				}
				,modalApp:0
				,eliminar: false
				,permisos: []
				,permisos_original: []
				,permiso: {
					permiso: null
					,descripcion: null
					,estatus: 1
				}
				,ver_permisos: false
			}
		}
		,mounted: function() {
			this.obtener_aplicaciones();
		}
		,methods: {
			obtener_aplicaciones: async function() {
				let response = (await api.listar_aplicaciones(this.options)).data;

				this.aplicaciones = response.data;

				this.dataGridConfig.paginator.pagina_actual = response.current_page;
				this.dataGridConfig.paginator.total_registros = response.total;
				this.dataGridConfig.paginator.registros_por_pagina = parseInt(response.per_page);
			}
			,obtener_aplicacion_permisos: async function() {
				this.permisos = (await api.listar_app_permisos(this.aplicacion.id)).data;
				this.permisos_original = Array.from(this.permisos);
			}
			,dataGridActions: function(tipo, valor) {
				if (tipo == 'options') {
					this.options = valor;
					this.obtener_aplicaciones();
				}else {
					this.seleccion = valor;
					this.seleccionar = true;
				}
			}
			,cancelar_app: function(e) {
				if (e) e.preventDefault();

				this.modalApp = 0;
				this.aplicacion = {
					nombre: null
					,descripcion: null
				}
				this.permisos = [];
				this.permisos_original = [];
				this.seleccionar = false;
			}
			,pre_editar: function() {
				if (this.seleccion.length > 0) {
					this.aplicacion = this.seleccion[0];
					this.obtener_aplicacion_permisos();
					this.modalApp = 2;
				}else
					this.$helper.showMessage('Error','Tienes que seleccionar una aplicación a editar', 'error', 'alert');
			}
			,guardar: async function(e) {
				e.preventDefault();

				if (this.modalApp == 1)
					await api.crear_aplicacion(this.aplicacion);
				else {
					await api.editar_aplicacion(this.aplicacion.id, this.aplicacion);
				}

				let nuevos = [];
				let borrados = [];

				for(let i=0; i<this.permisos.length; i++) {
					let encontrado = false;

					for(let a=0; a<this.permisos_original.length; a++) {
						if (this.permisos[i].id == this.permisos_original[a].id) {
							encontrado = true;
							a = this.permisos_original.length;
						}
					}

					if (!encontrado)
						nuevos.push(this.permisos[i]);
				}

				for(let i=0; i<this.permisos_original.length; i++) {
					let encontrado = false;
					
					for(let a=0; a<this.permisos.length; a++) {
						if (this.permisos_original[i].id == this.permisos[a].id) {
							encontrado = true;
							a = this.permisos.length;
						}
					}

					if (!encontrado)
						borrados.push(this.permisos_original[i].id);
				}

				this.$log.info('nuevos', nuevos);
				this.$log.info('borrados', borrados);

				if (nuevos.length > 0)
					for(let i=0; i<nuevos.length; i++) {
						await api.crear_permiso(this.aplicacion.id, nuevos[i]);
					}

				if (borrados.length > 0)
					await api.eliminar_permisos(this.aplicacion.id, {ids: borrados});

				this.cancelar_app();
				this.obtener_aplicaciones();
			}
			,pre_eliminar: function() {
				if (this.seleccion.length > 0) {
					this.aplicacion = this.seleccion[0];
					this.eliminar = true;
				}else
					this.$helper.showMessage('Error','Tienes que seleccionar una aplicación a eliminar', 'error', 'alert');
			}
			,cancelar_eliminar: function() {
				this.aplicacion = {
					nombre: null
					,descripcion: null
				}
				this.eliminar = false;
				this.seleccionar = false;
			}
			,eliminar_aplicaciones: async function() {
				await api.eliminar_aplicacion(this.aplicacion.id);

				this.cancelar_eliminar();
				this.obtener_aplicaciones();
			}
			,convertir_estatus: function(estatus) {
				return estatus == 1 ? 'Activo' : 'Inactivo';
			}
			,quitar_permiso: function(id) {
				let permisos = [];

				for(let i=0; i<this.permisos.length; i++) {
					if (this.permisos[i].id != id)
						permisos.push(this.permisos[i]);
				}

				this.permisos = permisos;
			}
			,cancelar_permiso: function() {
				this.permiso = {
					permiso: null
					,descripcion: null
					,estatus: 1
				}

				this.ver_permisos = false;
			}
			,agregar_permiso: function() {
				if (this.permiso.permiso == '' || this.permiso.permiso == null) {
					this.$helper.showMessage('Error','Tienes que especificar el permiso','error','alert');
					return false;
				}
				if ((this.permiso.descripcion == '' || this.permiso.descripcion == null) || this.permiso.descripcion.length < 3) {
					this.$helper.showMessage('Error','Tienes que especificar la descripción del permiso','error','alert');
					return false;
				}

				this.permisos.push(this.permiso);
				this.$log.info('permisos', this.permisos);
				this.cancelar_permiso();
			}
		}
	}
</script>

<style lang="scss">
	.list_permisos {
		.permisos-header {
			background-color: #242424;
			color: #fff;

			div {
				padding: 5px 10px;
			}
		}

		.permisos-list {
			div {
				padding: 5px 10px;

				img {
					width: 15px;
					cursor: pointer;
				}
			}

			div:last-child {
				text-align: center;
			}
		}

		.permisos-list:nth-child(2n+1) {
			background-color: #c7dfff;
		}
	}

	.permiso_cell {
		overflow: hidden;
	}
</style>